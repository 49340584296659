<style scoped>
</style>

<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true"
        :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm"
                    label-width="120px">
                    <el-form-item label="物料" prop="materialId">
                      <el-select v-model="m.materialId" filterable @change="changeM">
                        <el-option v-for="item in materials" :key="item.id" :value="item.id" :label="item.name"></el-option>
                      </el-select>
					  
					  <el-tag :key="tag" v-for="tag in partnerTags"  :disable-transitions="false"
					     style="margin-left: 10px;">
					      {{tag.name}}
					  </el-tag>
					  
                    </el-form-item>
                    <el-form-item label="数量：" prop="number">
                        <el-input v-model="m.number"></el-input>
                    </el-form-item>
                    <el-form-item label="价格：" prop="price">
                        <el-input v-model="m.price"></el-input>
                    </el-form-item>
                    <el-form-item label="规格：" prop="sku">
                        <el-input v-model="m.sku"></el-input>
                    </el-form-item>
                    <el-form-item label="预计到货时间：" prop="planReceiptDate">
                        <el-date-picker v-model="m.planReceiptDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="预计到货时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="到货时间：" prop="receiptDate">
                        <el-date-picker v-model="m.receiptDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="到货时间"></el-date-picker>
                    </el-form-item>
                     <el-form-item label="状态">
                        <input-enum enumName="purchaseStateEnum" v-model="m.state"></input-enum>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                purchaseId: [],
                materialId: [],
                materialName: [{ required: true, message: '请输入物料名称', trigger: 'blur' },],
                number: [{ pattern: /^\d{0,}$/, message: '数量必须为数字类型', trigger: 'blur' },],
                price: [{ pattern: /^\d{0,}$/, message: '价格必须为数字类型', trigger: 'blur' },],
                sku: [],
                planReceiptDate: [],
                ReceiptDate: [],
            },
            materials: [],
			partnerTags:[]
        }
    },
    methods: {
        open: function (data) {
            this.isShow = true;
            if (data.id) {
                this.title = "修改采购明细";
                this.m = data;
				this.changeM();
            } else {
                this.m = {
                    purchaseId: data.purchaseId,
                    materialId: '',
                    materialName: '',
                    number: 0,
                    price: 0,
                    sku: '',
                    planReceiptDate: '',
                    receiptDate: ''
                }
                this.title = "添加采购明细";
            }
        },
		changeM(){
			console.log(this.m.materialId);
			this.sa.get('/materialPartner/findByMaterial/'+ this.m.materialId).then(res=>{
				this.partnerTags = res.data;
			})
		},
        //提交采购明细信息
        ok: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let materials = this.materials.filter(item=>{
                      return (this.m.materialId == item.id);
                    })
                    if(materials.length> 0){
                      this.m.materialName = materials[0].name;
                    }
                    this.sa.post("/purchaseLine/save", this.m).then((res) => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {
        this.sa.get("/material/getMap").then(res => {
          console.log(66666666666, res.data);
            this.materials = res.data;
        })
    },
};
</script>